import React from 'react';
import { COLORS } from '@theme';

const Bild = ({ color = COLORS.darkBlue }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 37 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.68.313H.125v40H36.68v-40Zm-13.897 34.22h-3.599V5.345h3.599v29.19Zm-11.88-.01a1.573 1.573 0 0 0 1.567-1.574V20.288c0-.905-.736-1.642-1.64-1.652l.14.001c.824 0 1.5-.666 1.5-1.49V6.918a1.57 1.57 0 0 0-1.56-1.575h-7.1l-.001 29.18h7.093ZM8.883 8.948H7.407v8.315h1.477V8.947Zm0 11.888H7.407v10.078h1.477V20.835ZM32.997 5.343v29.19l-7.092-.01a1.573 1.573 0 0 1-1.568-1.574l.004-20.951a1.567 1.567 0 0 1 1.555-1.574h3.502V5.343h3.6Zm-5.075 8.646H29.4v16.924h-1.477V13.989ZM17.627 8.947h-3.598V5.344h3.598v3.603Zm0 25.587h-3.598v-24.11h3.598v24.11Z"
      fill={color}
    />
  </svg>
);

export default Bild;
